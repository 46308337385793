<template>
      <div class=" col-xs-12 col-sm-12 col-md-12">
        <div class="row check-info-content-body">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 py-2">
            <div class="check-info-content-body-head">Sığorta şirkəti</div>
            <div class="check-info-content-body-text">
              {{ companyName }}
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 py-2">
            <div class="check-info-content-body-head"> Müqavilə nömrəsi</div>
            <div id="carContract" class="check-info-content-body-text">
              {{ contractNumber }}
              <img src="@/assets/images/ins-copy.svg" @click="copyText('carContract')" />
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 py-2">
            <div class="check-info-content-body-head">Sığorta haqqı</div>
            <div class="check-info-content-body-text">
              {{ price }}
              <span >AZN</span>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 py-2">
            <div class="check-info-content-body-head"> Ödəniş kodu</div>
            <div id="carPayment" class="check-info-content-body-text">
              {{ paymentNumber }}
              <img src="@/assets/images/ins-copy.svg" @click="copyText('carPayment')" />
            </div>
          </div>
        </div>
  
        <div class="mt-3">
          <div class="iframe-section" style="height: 100vh;">
            <iframe src="https://www.e-pul.az/epay/az/category/insurance/icbari_insurance" frameborder="0"
              title="description"></iframe>
          </div>
        </div>
  
        
      </div>
  </template>
  
  <script>
  
  
  export default {
    data: () => ({
      homeURL:null,
      contractNumber:null,
      companyName:null,
      price:0,
      paymentNumber:null

    }),
    mounted() {
        this.homeURL =this.$route.path.startsWith("/travel");
        const params = this.$route.query;
        this.contractNumber = params.contractNumber;
        this.companyName= params.companyName;
        this.price = params.price;
        this.paymentNumber=params.paymentNumber
    },
    methods: {
      copyText(id) {
        var copyText = document.getElementById(id).textContent;
        navigator.clipboard.writeText(copyText);
      },
      
    },
  };
  </script>